<template>
  <div class="login">
    <div class="login-card">
      <h1 class="title"><img :src="app_icon" /><br />{{ app_name }}</h1>
      <el-card :body-style="{ padding: '0' }">
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
          <el-tab-pane :label="$t('login.welcome')" name="login">
            <div class="login-area">
              <el-form
                ref="form_login"
                :model="form_login"
                :rules="rules_login"
                label-width="80px"
                label-position="top"
              >
                <el-form-item :label="$t('login.user_name')" prop="username">
                  <el-input v-model="form_login.username"></el-input>
                </el-form-item>
                <el-form-item :label="$t('login.password')" prop="password">
                  <el-input
                    type="password"
                    v-model="form_login.password"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-alert :title="$t('login_extra_info')" type="info"> </el-alert>
              <el-button type="primary" @click="handleLogin">{{
                $t("login.enter")
              }}</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('login.register')" name="register">
            <div class="login-area">
              <el-form
                ref="form_register"
                :model="form_register"
                label-width="80px"
                label-position="top"
                :rules="rules_register"
              >
                <el-form-item :label="$t('login.user_name')" prop="username">
                  <el-input v-model="form_register.username"></el-input>
                </el-form-item>
                <el-form-item :label="$t('login.email')" prop="email">
                  <el-input v-model="form_register.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('login.password')" prop="password">
                  <el-input
                    type="password"
                    v-model="form_register.password"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('login.confirm_password')"
                  prop="password_confirm"
                >
                  <el-input
                    type="password"
                    v-model="form_register.password_confirm"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-button @click="handleRegister" type="primary">{{
                $t("login.register")
              }}</el-button>
            </div>
          </el-tab-pane>
        </el-tabs></el-card
      >
      <p
        style="width: 100%; text-align: center; color: white; font-size: 0.8rem"
      >
        <a
          href="https://beian.miit.gov.cn"
          style="text-decoration: none; color: white"
          target="_blank"
        >
          陇ICP备2021002031号</a
        >
      </p>
    </div>
    <div class="open-views">
      <el-menu
        :background-color="shared_layout.colors.menu_bg_color"
        :text-color="shared_layout.colors.menu_text_color"
        :router="true"
        ><el-menu-item index="/map-view-public">
          <span class="material-icons nav-item-icon"> map </span>
          <span slot="title"
            >{{ $t("buttons.map_view") }} ({{ $t("special.public") }})</span
          >
        </el-menu-item>
        <el-menu-item index="/downloads">
          <span class="material-icons nav-item-icon"> cloud_download </span>
          <span slot="title">{{ $t("buttons.download") }} </span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="lang-switch">
      <div v-if="lang_status == 'zh_cn'">
        <span style="color: white">中文</span>
        <span style="color: white"> / </span>
        <span style="color: gray">EN</span>
      </div>
      <div v-else>
        <span style="color: gray">中文</span>
        <span style="color: white"> / </span>
        <span style="color: white">EN</span>
      </div>
      <el-switch
        :value="lang_status"
        active-color="#13ce66"
        inactive-color="#13ce66"
        active-value="en"
        inactive-value="zh_cn"
        @change="langChange"
      >
      </el-switch>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("login.enter_password_again")));
      } else if (value !== this.form_register.password) {
        callback(new Error(this.$t("login.inconsistent")));
      } else {
        callback();
      }
    };
    return {
      app_icon: require("@/assets/field.svg"),
      activeName: "login",
      form_login: {
        username: "",
        password: "",
      },
      form_register: {
        username: "",
        email: "",
        password: "",
        password_confirm: "",
      },
      rules_register: {
        username: [
          {
            required: true,
            message: this.$t("login.please_enter_username"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("login.please_enter_email"),
            trigger: "blur",
          },
        ],

        password: [
          {
            required: true,
            message: this.$t("login.please_enter_pasword"),
            trigger: "blur",
          },
        ],

        password_confirm: [
          {
            required: true,
            message: this.$t("login.please_confirm_password"),
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      rules_login: {
        username: [
          {
            required: true,
            message: this.$t("login.please_enter_username"),
            trigger: "blur",
          },
        ],

        password: [
          {
            required: true,
            message: this.$t("login.please_enter_pasword"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      app_name: (state) => state.app_name,
      lang_status: (state) => state.lang_status,
    }),
  },
  methods: {
    ...mapActions(["confirmUserLogin", "getLangChange"]),
    langChange(e) {
      let vm = this;
      vm.$i18n.locale = e;
      vm.getLangChange(e);
    },
    handleClick(tab, event) {
      return [tab, event];
    },
    async handleLogin() {
      let vm = this;
      const form = new FormData();
      form.append("username", this.form_login.username);
      form.append("password", this.form_login.password);
      this.$post_api(apiURL.login, form)
        .then((res) => {
          if (res.access_token.length > 0) {
            this.confirmUserLogin({
              name: this.form_login.username,
              access_token: res.access_token,
            });
            return true;
          }
        })
        .then((res) => {
          vm.showApiMsg(this.$t("login.login_success"), "success");
          this.$router.push("/dashboard");
          return res;
        })
        .catch(function (error) {
          vm.showApiMsg(this.$t("login.login_error"), "error");
          console.log(error.response);
        });
    },
    async handleRegister() {
      let vm = this;
      this.$refs["form_register"].validate(async (valid) => {
        if (valid) {
          var created = await this.$post_api(apiURL.user.register, {
            name: this.form_register.username,
            email: this.form_register.email,
            password: this.form_register.password,
          })
            .then((res) => {
              if (res.registered === true) {
                vm.showApiMsg(vm.$t("login.success_info"), "success");
                return true;
              } else {
                vm.showApiMsg(vm.$t("login.signup_fail_info"), "error");
                return false;
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                vm.showApiMsg(vm.$t("login.existing_username_email"), "error");
              } else if (error.response.status === 403) {
                vm.showApiMsg(vm.$t("login.illegal_email"), "error");
              }
            });

          if (created === true) {
            this.activeName = "login";
            this.form_login.username = this.form_register.username;
            this.form_login.password = this.form_register.password;
            // await vm.handleLogin();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/main";
$card-width: 320px;
.login {
  background: $dark-bg-color;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  //   height: 1000px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  & ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .login-card {
    // margin-top: 10vh;
    margin-bottom: 10vh;
    .title {
      text-align: center;
      color: white;
      // font-weight: normal;
      img {
        height: 100px;
      }
    }

    .login-area {
      margin: 20px;
      margin-top: 0;
    }

    text-align: left;
    // padding: 20px;
    width: $card-width;
    min-width: $card-width;
    max-width: $card-width;
    // background: white;
    // border-radius: $card-border-radius;

    .el-button {
      width: 100%;
      margin-top: 2rem;
    }

    // .el-tabs__header {
    //   padding: 20px !important;
    // }
    // .el-tabs__nav-scroll {
    //   text-align: center;
    // }
  }
}
.open-views {
  position: absolute;
  bottom: 80px;
  left: 20px;
  .el-menu {
    border-right: none;
  }
}
.lang-switch {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
</style>

<style lang="scss">
.el-form-item {
  margin-bottom: 20px;
  .el-form-item__label {
    padding: 0 !important;
    line-height: 30px !important;
  }
}
</style>
